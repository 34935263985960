import { Link } from "@remix-run/react";
import BackGroundVideoWorldRoute from "./backgroundvideoworld";

export default function LandingPage() {
  return (
    <div className="bg-[#121822]">
      <div className="">
        <div className="">
          <BackGroundVideoWorldRoute />

          <div className="mx-auto mt-10 flex items-center gap-x-6 justify-center">
            <Link
              to="/login"
              className="rounded-md bg-button-dark px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400 hover:animate-pulse"
            >
              Login
            </Link>
            <Link
              to="/registercompany/basics"
              className="text-sm font-semibold leading-6 text-white"
            >
              Register <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
