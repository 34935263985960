import {
  Bars4Icon,
  CalendarIcon,
  ClockIcon,
  PhotoIcon,
  TableCellsIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import { type LoaderFunctionArgs } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { GeneralErrorBoundary } from "~/components/errorboundary";
import LandingPage from "~/components/landingPage";
import { loginAuthenticator } from "~/services/auth.server";
import { classNames } from "~/utils/misc";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  let authenticatedIDs = await loginAuthenticator.isAuthenticated(request);

  if (!authenticatedIDs) {
    return null;
  }
  return { IsUserAlreadyLoggedIn: Boolean(authenticatedIDs), authenticatedIDs };
};

const items = [
  {
    title: "Tell us about your interests",
    description: "Another to-do system you’ll try but eventually give up on.",
    icon: Bars4Icon,
    background: "bg-pink-500",
  },
  {
    title: "Create a Tradeproduct",
    description: "Stay on top of your deadlines, or don’t — it’s up to you.",
    icon: CalendarIcon,
    background: "bg-yellow-500",
  },
  {
    title: "Update your Profile",
    description: "Great for mood boards and inspiration.",
    icon: PhotoIcon,
    background: "bg-green-500",
  },
  {
    title: "Check out your dashboard",
    description: "Track tasks in different stages of your project.",
    icon: ViewColumnsIcon,
    background: "bg-blue-500",
  },
  {
    title: "Create a Paymentmethod",
    description: "Lots of numbers and things — good for nerds.",
    icon: TableCellsIcon,
    background: "bg-indigo-500",
  },
  {
    title: "Create a Timeline",
    description: "Get a birds-eye-view of your procrastination.",
    icon: ClockIcon,
    background: "bg-purple-500",
  },
];

export default function IndexRoute() {
  let data = useLoaderData<typeof loader>();
  let { t } = useTranslation();

  return (
    <>
      {data?.IsUserAlreadyLoggedIn ? (
        <>
          <div className="my-auto mx-auto text-black dark:text-white justify-center justify-items-center">
            <div>
              <h2 className="text-base font-semibold leading-6 text-black dark:text-white">
                {t("firststep")}
              </h2>
              <p className="mt-1 text-sm text-black dark:text-white">
                {t("firststepdescription")}
              </p>
              <ul className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
                {items.map((item, itemIdx) => (
                  <li key={itemIdx} className="flow-root">
                    <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-button-light dark:hover:bg-button-dark">
                      <div
                        className={classNames(
                          item.background,
                          "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg"
                        )}
                      >
                        <item.icon
                          aria-hidden="true"
                          className="h-6 w-6 text-black dark:text-white"
                        />
                      </div>
                      <div>
                        <h3 className="text-sm font-medium text-black dark:text-white">
                          <Link to="#" className="focus:outline-none">
                            <span
                              aria-hidden="true"
                              className="absolute inset-0"
                            />
                            <span>{item.title}</span>
                            <span aria-hidden="true"> &rarr;</span>
                          </Link>
                        </h3>
                        <p className="mt-1 text-sm text-black dark:text-white">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-4 flex">
                <Link
                  to="#"
                  className="text-sm font-medium text-button-light dark:text-button-dark hover:text-link-light dark:hover:text-link-dark"
                >
                  Or skip all these steps
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LandingPage />
      )}
    </>
  );
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
